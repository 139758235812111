<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-layout v-if="$auth.getRole() !== 'student'" row>
          <v-flex sm8>
            <auth-info></auth-info>
          </v-flex>
          <v-flex sm4>
            <member-count></member-count>
          </v-flex>
        </v-layout>

        <v-layout v-else-if="$auth.getRole() === 'student'">
          <v-flex sm12>
            <auth-info></auth-info>
          </v-flex>
        </v-layout>

        <div class="dashboard">
          <div class="row">
            <transaction-analytic></transaction-analytic>
            <!-- <div class="col-8 pa-0">
              <div class="earning">
                <div class="icon-box">
                  <div class="icon-img">
                    <img src="/images/photos/earning.svg" alt="" />
                  </div>
                  <v-menu open-on-hover top offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="#5E35B1" dark v-bind="attrs" v-on="on">
                        <span class="material-icons"> more_horiz </span>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(item, index) in totalEarning"
                        :key="index"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

                <h1>$500.00</h1>
                <p>Total Earning</p>
              </div>

              <div class="order">
                <div class="icon-box">
                  <div class="icon-img">
                    <span class="material-icons"> local_mall </span>
                  </div>
                  <v-menu disabled top offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="#1D88E5" dark v-bind="attrs" v-on="on">
                        Year
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item v-for="(item, index) in items" :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

                <h1>$961</h1>
                <p>Total Order</p>
              </div>
            </div>
            <div class="col-4 pa-0">
              <div class="income">
                <div class="icon-box">
                  <div class="icon-img">
                    <span class="material-icons"> table_chart </span>
                  </div>
                  <div class="text">
                    <h1>$230k</h1>
                    <p>Total Income</p>
                  </div>
                </div>
              </div>

              <div class="income-cart">
                <div class="icon-box">
                  <div class="icon-img">
                    <span class="material-icons"> storefront </span>
                  </div>
                  <div class="text">
                    <h1>$230k</h1>
                    <p>Total Income</p>
                  </div>
                </div>
              </div>
            </div> -->

            <br />
            <div class="col-8 pt-0 pl-0 pd-0">
              <div class="growth">
                <div class="overlay">
                  <div class="growth-header-content">
                    <div class="growth-text">
                     
                       <span style="color: #4caf50;">Total present </span> / <span style="color: #ff5252;">Total Absent</span> / <span style="color: #1876d2;">Total Leave</span> / <span style="color: #fb8c00;">Total Late</span> 
                       <v-btn icon ma-0  @click=setData()>
                            <v-icon>autorenew</v-icon>
                        </v-btn>
                      <h1> 
                        <span style="color: #4caf50;">
                         {{ formatNumber(totalPresent)  }}
                        </span>
                        /
                        <span style="color: #ff5252;">
                         {{ formatNumber(totalAbsent) }}
                        </span>
                        /
                        <span style="color: #1876d2;">
                         {{ formatNumber(totalLeave) }}
                        </span>
                        /
                        <span style="color: #fb8c00;">
                         {{ formatNumber(totalLate) }}
                        </span>

                      </h1>
                    
                    </div>

                    <!-- <div class="row"> -->
                    <div class="col-2 pa-0">
                     
                      <div class="select-button">
                        
                        <v-select
                          :items="items"
                          :full-width="false"
                          label="Today"
                          outlined
                          v-model="attendance_day"
                          @change="getAttendanceCountFromStorage()"
                        ></v-select>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>

                  <apexchart
                    height="582"
                    type="bar"
                    :options="options"
                    :series="series"
                    :series-2="series"
                    :key="refreshKey"
                  ></apexchart>
                  <!-- <div class="inner-overlay">
                    <div style="text-align: center">
                      <div style="margin-top:200px;"></div>
                      <v-icon style="font-size: 50px;color:red;"
                        >info</v-icon
                      >
                      <p style="font-size: 30px;margin-bottom:0;margin-top:20px;">Attendance Record Coming Soon</p>
                      <small style="font-size:14px;">Attendance Summary Report will be displayed here in our next update. Estimated update arrival is <strong>7th Nov</strong></small>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <areaChart></areaChart>
            <!-- <div class="col-4 pa-0">
              <div class="stock">
                <div class="header-content">
                  <h1>Transaction Summary</h1>
                  <span class="material-icons"> more_horiz </span>
                </div>

                <div class="chart-section">
                  <div class="bill">
                    <div class="display-bill">
                      <h1>Bajaj Finery</h1>
                      <p>10% Profit</p>
                    </div>

                    <div class="bill">
                      <h1>$1839.00</h1>
                    </div>
                  </div>

                  <areaChart></areaChart>
                </div>

                <div class="profit-statement">
                  <div
                    class="profit-loss"
                    v-for="(item, index) in transactions"
                    :key="index"
                  >
                    <div class="display-bill">
                      <h1>{{ item.name }}</h1>
                      <p>{{ item.transaction_date }}</p>
                    </div>

                    <div class="bill">
                      <h1>{{ item.amount.currency() }}</h1>
                      <span class="material-icons"> expand_less </span>
                    </div>
                  </div>
                </div>
                <div class="stock-footer">
                  <router-link to="/receipt-report">
                    <h1>View All</h1>
                  </router-link>
                  <span class="material-icons side-bar">
                    arrow_forward_ios
                  </span>
                </div>
              </div>
            </div> -->
          </div>
        </div>

        <div class="monthly-analytics-section">
          <h1 class="monthly-analytics-title">Service wise</h1>
          <p class="monthly-analytics-sub-title">Monthly Analytics</p>
          <div class="monthly-analytic-features">
            <div class="row">
              <div class="col-8">
                <div class="row">
                  <div class="col-3">
                    <div class="monthly-analytics-box">
                      <a href="#">
                        <div class="analytics-box">
                          <div class="analytics-icon">
                            <img src="/images/photos/contract.png" alt="" />
                          </div>
                        </div>

                        <div class="analytics-title">
                          <h1>Admission</h1>
                        </div>

                        <div class="analytics-sub-title">New Admission</div>
                        <div class="analytics-percent">
                          {{ monthly_analysis_count.new_admission || 'N/A'  }}
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="monthly-analytics-box">
                      <a href="#">
                        <div class="analytics-box">
                          <div class="analytics-icon">
                            <img src="/images/photos/all.png" alt="" />
                          </div>
                        </div>

                        <div class="analytics-title">
                          <h1>Staff Left</h1>
                        </div>

                        <div class="analytics-sub-title">Total Left</div>

                        <div class="analytics-percent">
                          {{ monthly_analysis_count.left_staff || 'N/A' }}
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="monthly-analytics-box">
                      <a href="#">
                        <div class="analytics-box">
                          <div class="analytics-icon">
                            <img src="/images/photos/voucher.png" alt="" />
                          </div>
                        </div>

                        <div class="analytics-title">
                          <h1>Voucher</h1>
                        </div>

                        <div class="analytics-sub-title">Sales Earning</div>

                        <div class="analytics-percent">116%</div>
                      </a>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="monthly-analytics-box">
                      <a href="#">
                        <div class="analytics-box">
                          <div class="analytics-icon">
                            <img src="/images/photos/teamwork.png" alt="" />
                          </div>
                        </div>

                        <div class="analytics-title">
                          <h1>New Staff</h1>
                        </div>

                        <div class="analytics-sub-title">Total New Staff</div>

                        <div class="analytics-percent">
                          {{ monthly_analysis_count.new_staff || 'N/A'  }}
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="monthly-analytics-box">
                      <a href="#">
                        <div class="analytics-box">
                          <div class="analytics-icon">
                            <img src="/images/photos/planner.png" alt="" />
                          </div>
                        </div>

                        <div class="analytics-title">
                          <h1>Events</h1>
                        </div>

                        <div class="analytics-sub-title">Total Events</div>

                        <div class="analytics-percent">
                          {{ total_event_count || 'N/A'  }}
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="monthly-analytics-box">
                      <a href="#">
                        <div class="analytics-box">
                          <div class="analytics-icon">
                            <img src="/images/photos/sms.png" alt="" />
                          </div>
                        </div>

                        <div class="analytics-title">
                          <h1>SMS Sent</h1>
                        </div>

                        <div class="analytics-sub-title">Total Sent</div>

                        <div class="analytics-percent">
                          {{ monthly_analysis_count.sms_count || 'N/A'  }}
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="monthly-analytics-box">
                      <a href="#">
                        <div class="analytics-box">
                          <div class="analytics-icon">
                            <img src="/images/photos/bill.png" alt="" />
                          </div>
                        </div>

                        <div class="analytics-title">
                          <h1>Notices</h1>
                        </div>

                        <div class="analytics-sub-title">Total Notices</div>

                        <div class="analytics-percent">
                          {{ monthly_analysis_count.notice || 'N/A'  }}
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="monthly-analytics-box">
                      <a href="#">
                        <div class="analytics-box">
                          <div class="analytics-icon">
                            <img src="/images/photos/bookshelf.png" alt="" />
                            <!-- <span class="material-icons">school</span> -->
                          </div>
                        </div>

                        <div class="analytics-title">
                          <h1>Library</h1>
                        </div>

                        <div class="analytics-sub-title">Issued/Returned</div>

                        <div class="analytics-percent">
                          {{ monthly_analysis_count.book_issue  || 'N/A'  }} / {{
                            monthly_analysis_count.book_return  || 'N/A' 
                          }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-4">
                <div class="analytics-section">
                  <span class="material-icons analytics-top-icon">
                    settings
                  </span>
                  <h1>Refer a School.</h1>
                  <p class="analytics-statement" style="margin-bottom:0;">
                    Submit a form with school’s information and receive two
                    months of free eAcademy subscription 🎉 🎉.
                  </p>
                  <p style="margin-top:0;">....</p>
                  <add-button
                    class="read-button"
                    :onCurrentBatch="false"
                    permission="batch-create"
                    @action="referal = true"
                  >
                    Refer Now
                  </add-button>

                

                  <div class="school-icon">
                    <div class="icon-section">
                      <img class="icon-1" src="/images/girl.jpeg" alt="" />
                      <img class="icon-2" src="/images/photos/bus.png" alt="" />

                      <img
                        class="icon-3"
                        style="margin-right: 180px"
                        src="/images/photos/books.png"
                        alt=""
                      />
                    </div>

                    <!-- <div class="icon-section">
                      <img src="/images/girl.jpeg" alt="">
                    </div>

                    <div class="icon-section">
                      <img src="/images/girl.jpeg" alt="">
                    </div> -->
                  </div>
                
                </div>
              </div>
              <!-- <div class="col-2 pa-0">
                <div class="monthly-analytics-1">
                  <div class="icon-box">
                    <div class="icon-img">
                      <span class="material-icons">school</span>
                    </div>
                    <div class="text">
                      <h1>78</h1>
                      <p>Admission</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-2 pa-0">
                <div class="monthly-analytics">
                  <div class="icon-box">
                    <div class="icon-img">
                      <span class="material-icons"> table_chart </span>
                    </div>
                    <div class="text">
                      <h1>40</h1>
                      <p>Total Left</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-2 pa-0">
                <div class="monthly-analytics-2">
                  <div class="icon-box">
                    <div class="icon-img">
                      <span class="material-icons"> table_chart </span>
                    </div>
                    <div class="text">
                      <h1>5</h1>
                      <p>Voucher</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-2 pa-0">
                <div class="monthly-analytics-1">
                  <div class="icon-box">
                    <div class="icon-img">
                      <span class="material-icons">account_circle</span>
                    </div>
                    <div class="text">
                      <h1>10</h1>
                      <p>New Staff</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-2 pa-0">
                <div class="monthly-analytics">
                  <div class="icon-box">
                    <div class="icon-img">
                      <span class="material-icons"> event </span>
                    </div>
                    <div class="text">
                      <h1>4</h1>
                      <p>Events</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-2 pa-0">
                <div class="monthly-analytics-2" style="margin-right: 0px">
                  <div class="icon-box">
                    <div class="icon-img">
                      <span class="material-icons"> sms </span>
                    </div>
                    <div class="text">
                      <h1>500</h1>
                      <p>SMS Sent</p>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <!-- Calendar Start -->
        <calendar v-on:destroyLocalStorage="getEventCount()"></calendar>
        <!-- Calendar End -->

        <!-- <br /> -->

        <!-- <v-layout v-if="$auth.getRole() !== 'student'" row>
          <v-flex sm4>
            <v-card
              height="100%"
              style="border-top:10px solid #ccc;"
              class="elevation-1"
              outlined
            >
              <grade-pie
                v-if="!['teacher'].includes($auth.getRole())"
              ></grade-pie>
              <teacher-routine
                v-if="['teacher'].includes($auth.getRole())"
              ></teacher-routine>
              <br />
              <collection-donut
                v-if="['administrator', 'accountant'].includes($auth.getRole())"
              ></collection-donut>
            </v-card>
          </v-flex>
          <v-flex sm8>
            <v-card
              height="100%"
              style="border-top:10px solid #ccc;"
              class="mx-auto"
              outlined
            >
              <daily-attendance></daily-attendance>
              <annual-collection
                v-if="['administrator', 'accountant'].includes($auth.getRole())"
              ></annual-collection>
            </v-card>
          </v-flex>
        </v-layout> -->

        <!-- <monthly-analytic
          v-if="$auth.getRole() !== 'student'"
        ></monthly-analytic> -->

        <br />
        <br />

        <!-- <div style="margin-top: 10px;margin-bottom: 0;" class="divider">
          <span style="color:#666;padding-left: 5px"
            ><v-icon>calendar_today</v-icon> School Calendar</span
          >
        </div> -->
        <!-- <div class="calendar-dash">
          <calendar :handler="false"></calendar>

        </div> -->
        <br />
      </v-flex>

      <!-- <div class="frame">
        <div class="center">
          <div class="light"></div>
          <svg
            width="46px"
            height="54px"
            viewBox="0 0 76 94"
            id="bulb"
            onclick="void(0);"
          >
            <path
              d="M76,37.037 C76,59.939 55.6428571,75.427 55.6428571,93.5 L20.3571429,93.5 C20.3571429,75.427 0,59.9335 0,37.037 C0,13.1505 18.9891429,0 37.9782857,0 C56.9891429,0 76,13.167 76,37.037 L76,37.037 Z"
            ></path>
          </svg>
          <svg width="20px" height="23px" viewBox="0 0 32 33" id="base">
            <path
              d="M29.3333333,0 L2.66666667,0 C1.19466667,0 0,1.232 0,2.75 C0,4.268 1.19466667,5.5 2.66666667,5.5 L29.3333333,5.5 C30.8053333,5.5 32,4.268 32,2.75 C32,1.232 30.8053333,0 29.3333333,0 L29.3333333,0 Z M29.3333333,11 L2.66666667,11 C1.19466667,11 0,12.232 0,13.75 C0,15.268 1.19466667,16.5 2.66666667,16.5 L29.3333333,16.5 C30.8053333,16.5 32,15.268 32,13.75 C32,12.232 30.8053333,11 29.3333333,11 L29.3333333,11 Z M30.6666667,22 L1.33333333,22 L9.072,31.1245 C10.0853333,32.3125 11.552,33 13.088,33 L18.9173333,33 C20.4533333,33 21.9146667,32.3125 22.928,31.1245 L30.6666667,22 L30.6666667,22 Z"
            ></path>
          </svg>
        </div>
      </div> -->
    </v-layout>
    
    <v-dialog
      v-model="personalEventDialog"
      scrollable
      persistent
      max-width="720px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Personal Event</span>
        </v-card-title>
        <v-card-text>
          <PersonalEvent
            :resuseComponent="true"
            :personalEventData="personalEventData"
            @checkStatus="checkStatus"
            @getId="getId"
            ref="personalEventComp"
            :key="compKey"
          ></PersonalEvent>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="selectedPersonalId.length == 0"
            outlined
            color="success"
            text
            @click="remindMeTomorrow()"
            >Remind Me Tomorrow</v-btn
          >
          <v-btn
            :disabled="selectedPersonalId.length == 0"
            outlined
            color="primary"
            text
            @click="markAsCompleted()"
            >Mark As Completed</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="referal" scrollable persistent max-width="550px">
      <v-card outlined class="primary--border">
        <v-card-title class="primary white--text">
          <span class="title">Refer a School</span>
        </v-card-title>
        <v-card-text>
          <div class="mt-2">
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      label="School Name*"
                      autocomplete="off"
                      required
                      class="pa-0"
                      v-model="form.name"
                      name="name"
                      outlined
                      dense
                      :error-messages="form.errors.get('name')"
                      :rules="[(v) => !!v || 'School Name is required']"
                    />
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      label="School Address*"
                      autocomplete="off"
                      required
                      class="pa-0"
                      v-model="form.address"
                      name="address"
                      outlined
                      dense
                      :error-messages="form.errors.get('address')"
                      :rules="[(v) => !!v || 'School Address is required']"

                    />
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      type="number"
                      label="Phone No.*"
                      autocomplete="off"
                      required
                      class="pa-0"
                      v-model="form.phone_number"
                      name="number"
                      outlined
                      dense
                      :error-messages="form.errors.get('number')"
                      :rules="mobileRules"

                    />
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      label="Contact Person*"
                      autocomplete="off"
                      required
                      class="pa-0"
                      v-model="form.contact_person"
                      name="contact"
                      outlined
                      dense
                      :error-messages="form.errors.get('contact')"
                      :rules="[(v) => !!v || 'Contact Person is required']"

                    />
                  </v-flex>
                  <v-textarea
                    label="Description"
                    no-resize
                    rows="5"
                    v-model="form.description"
                    row-height="25"
                    outlined
                  ></v-textarea>
                </v-layout>
              </v-container>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="referal=false,form.reset()">Close</v-btn>
          <v-btn color="success" outlined text @click="referSchool()">Send </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ls from "@/library/Storage";
import Form from "@/library/Form";
import AuthInfo from "./../blocks/authinfo";
import MemberCount from "./../blocks/membercount";
import AreaChart from "./../blocks/areaChart";

// import GradePie from "./../blocks/gradepie";
// import CollectionDonut from "./../blocks/collectiondonut";
// import DailyAttendance from "./../blocks/dailyattendance";
// import AnnualCollection from "./../blocks/annualcollection";
// import MonthlyAnalytic from "./../blocks/monthlyanalytic";
// import TeacherRoutine from "./../blocks/teacherroutine";
// import Calendar from "./../../../calendar/_components/Calendar/index";

import Calendar from "./../../../calendar/_components/Calendar/newCalendar.vue";
import PersonalEvent from "../../../calendar/_components/Calendar/personalevent.vue";
import transactionAnalytic from "./../blocks/transactionAnalytic";
// import New from "./../blocks/newDashboard";
// import new fron "./../"
// import ButtomCard from './../blocks/buttomcard'

import Mixins from "@/library/Mixins";
import { formatNumber } from "../../../../library/helpers";
export default {
	mixins: [Mixins],
	components: {
		AuthInfo,
		MemberCount,
		AreaChart,
		PersonalEvent,
		transactionAnalytic,
		// GradePie,
		// CollectionDonut,
		// DailyAttendance,
		// AnnualCollection,
		// TeacherRoutine,
		// ButtomCard,
		// MonthlyAnalytic,
		Calendar,
	},
	data: () => ({
		mobileRules: [
			v => !!v || 'Phone Number is required',
			v => (v && v.length == 10) || 'Phone Number must be of 10 characters',
		],
		// mobileRule:[
		//   (v) => !!v || 'Mobile is required!' , v.length !== 10 || 'Mobile Number must be of 10 Digit'
		// ],
		referal: false,
		setCookieStatus: false,
		currentDate: "",
		compKey: 0,
		personalEventData: "",
		selectedPersonalId: "",
		personalEventDialog: false,
		items: [
			{value:"today",text:"Today"},
			{value:"month",text:"This Month"},
			{value:"year",text:"This Year"},
		],
		/////////////////////////bar chart////////////////////////////////////////
		options: {
			chart: {
				stacked: true,
				toolbar: {
					show: false,
				},
			},
			xaxis: {
				categories: [],
			},
			colors:['#4caf50', '#ff5252', '#1876d2','#fb8c00']
      
		},
		series:[],
		refreshKey:0,
		// series: [
		//   {
		//     type: "column",
		//     name: "series-1",
		//     data: [30, 40, 45, 50, 49, 60, 70, 91, 52, 66, 94, 92],
		//   },
		//   {
		//     type: "column",
		//     name: "series-2",
		//     data: [50, 40, 45, 50, 49, 60, 70, 91, 52, 66, 94, 32],
		//   },
		//   {
		//     type: "column",
		//     name: "series-3",
		//     data: [120, 160, 30, 40, 50, 60, 70, 80, 90, 150, 20, 30],
		//   },
		//   {
		//     type: "column",
		//     name: "series-4",
		//     data: [50, 0, 0, 70, 0, 0, 0, 40, 0, 50],
		//   },
		// ],
		// chart: {
		//   type: "bar",
		//   height: 350,
		//   stacked: true,
		//   toolbar: {
		//     show: false,
		//   },
		//   zoom: {
		//     enabled: false,
		//   },
		// },
		// responsive: [
		//   {
		//     breakpoint: 480,
		//     options: {
		//       legend: {
		//         position: "bottom",
		//         offsetX: -10,
		//         offsetY: 0,
		//       },
		//     },
		//   },
		// ],
		// plotOptions: {
		//   bar: {
		//     horizontal: false,
		//     borderRadius: 10,
		//   },
		// },

		// legend: {
		//   position: "right",
		//   offsetY: 40,
		// },
		// fill: {
		//   opacity: 1,
		// },
		monthly_analysis_count: {},
		transactions: [],
		total_event_count: 0,
		form: new Form(
			{
				name: "",
				address:"",
				phone_number:"",
				contact_person:"",
				description:"",
			},
			"/api/school-referals"
		),
		attendance_day:'today',

	}),
	computed: {
		totalPresent(){
			let total_present = 0;
			let total_presnet_data = this.series.filter((item)=>{
				if(item.name =='present'){
					return item
				}
			})
			if(total_presnet_data.length >0){
				total_present = total_presnet_data[0]['data'].reduce((a, b) => parseInt(a) + parseInt(b), 0);
			}
			return total_present;
		},
		totalAbsent(){
			let total_absent = 0;
			let total_absent_data = this.series.filter((item)=>{
				if(item.name =='absent'){
					return item
				}
			})
			if(total_absent_data.length >0){
				total_absent = total_absent_data[0]['data'].reduce((a, b) => parseInt(a) + parseInt(b), 0);
			}
			return total_absent;
		},
		totalLeave(){
			let total_leave = 0;
			let total_leave_data = this.series.filter((item)=>{
				if(item.name =='leave'){
					return item
				}
			})
			if(total_leave_data.length >0){
				total_leave = total_leave_data[0]['data'].reduce((a, b) => parseInt(a) + parseInt(b), 0);
			}
			return total_leave;
		},
		totalLate(){
			let total_late = 0;
			let total_late_data = this.series.filter((item)=>{
				if(item.name =='late'){
					return item
				}
			})
			if(total_late_data.length >0){
				total_late = total_late_data[0]['data'].reduce((a, b) => parseInt(a) + parseInt(b), 0);
			}
			return total_late;
		},
		// ...mapState(['user']),
	},
	mounted() {
		this.getCurretnDate();
		this.getEventCount();
		this.getMonthlyAnalysisData();
		this.$auth.getServices("ACCOUNT");
		let persona_event_cookie = ls.cookieGet("_personalevent");
		if (
			persona_event_cookie === undefined &&
      persona_event_cookie !== this.currentDate
		) {
			this.getPersonalEventList();
		}
		this.getAttendanceCount();

   
	},
	methods: {
		formatNumber,
		getCurretnDate() {
			var today = new Date();
			var dd = String(today.getDate()).padStart(2, "0");
			var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
			var yyyy = today.getFullYear();

			today = yyyy + "-" + mm + "-" + dd;
			this.currentDate = today;
		},

		viewProfile() {
			this.$router.push({ name: "my-account" });
		},
		calendarView() {
			this.$router.push({ name: "calendar" });
		},
		dueCollectionView() {
			this.$router.push({ name: "collection-due" });
		},
		checkStatus() {
			// this.getPersonalEventList();
		},
		async getMonthlyAnalysisData() {
			var date = new Date();
			date.setDate(date.getDate()-1);
			let monthly_analysis_data = {};
			let today = new Date().toISOString().slice(0, 10)
      
			const yesterdayDay = date.getDate().toString().length == 1 ? 0+date.getDate().toString():date.getDate()
			const yesterdayMonth =  (date.getMonth()+1).toString.length == 1 ? 0+(date.getMonth()+1) : (date.getMonth()+1)
      
			let yesterday = date.getFullYear() + '-' + yesterdayMonth + '-' + yesterdayDay;
			// let yesterday = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
			this.$localStorage.removeFromStorage("monthly_analysis_"+yesterday);

			let data = this.$localStorage.getFromStorage("monthly_analysis_"+today);
			if (!data) {
				await this.$rest.get("/api/get-monthly-analysis-data").then((res) => {
					monthly_analysis_data = res.data;
					this.$localStorage.setToStorage("monthly_analysis_"+today, res.data);
				});
			} else {
				monthly_analysis_data = data;
			}
			this.monthly_analysis_count = monthly_analysis_data;
		},
		getEventCount() {
			let today = new Date().toISOString().slice(0, 10)
      
			let data = this.$localStorage.getFromStorage("school_event_count_"+today);
			if (data == null || data == undefined) {
				this.$rest.get("/api/get-event-count").then((res) => {
					if (res.data.length > 0) {
						this.total_event_count = res.data[0].total;
						this.$localStorage.setToStorage(
							"school_event_count_"+today,
							res.data[0].total
						);
					}
				});
			} else {
				this.total_event_count = data;
			}
		},
		markAsCompleted() {
			this.setCookieStatus = true;
			this.$refs.personalEventComp.updateEventStatus().then(() => {
				this.compKey = this.compKey++;
				this.getPersonalEventList();
				setTimeout(() => {
					this.compKey = this.compKey + 1;
				}, 1000);
			});
		},
		remindMeTomorrow() {
			this.setCookieStatus = true;
			this.$rest
				.post("/api/remind-me-tomorrow", {
					selectedId: this.selectedPersonalId,
				})
				.then((res) => {
					if (res.status == 200) {
						this.getPersonalEventList();
						setTimeout(() => {
							this.compKey = this.compKey + 1;
						}, 1000);
						this.$events.fire("notification", { message: data.message });
					}
				})
				.catch((e) => {})
				.finally((f) => {
					// this.setCookieStatus = false;
				});
		},
		getId(selectedData) {
			this.selectedPersonalId = selectedData.map((a) => a.id);
		},
		getPersonalEventList() {
			this.$rest
				.get(
					"/api/personal-event?status=incomplete" +
            "&frm_date=" +
            "&to_date=" +
            "&reuseComponent=" +
            true
				)
				.then(({ data }) => {
					if (data.data.length > 0) {
						this.personalEventData = data;
						this.personalEventDialog = true;
					} else {
						if (this.setCookieStatus) {
							this.personalEventDialog = false;
							ls.cookieSet("_personalevent", this.currentDate);
							this.setCookieStatus = false;
						}
					}
				});
		},
		referSchool(){
			if (this.$refs.form.validate()) {
				this.form.fireFetch = false;
				this.form.store().then((res) => {
					if(res.status == 200){
						this.referal=false; 
					}
				});
			}
		},
		getAttendanceCountFromStorage(){
			if(this.attendance_day === 'year'){
				this.refreshKey  = this.refreshKey +1

				if(window.localStorage.getItem("year_attendance_count") !== null){
					let year_attendance_record = JSON.parse(
						window.localStorage.getItem("year_attendance_count")
					);
					this.options.xaxis.categories = year_attendance_record[0]['categories'];
					this.series = year_attendance_record[0]['series'];
				}else{
					this.getAttendanceCount();
				}
			}
			if(this.attendance_day === 'month'){
				this.refreshKey  = this.refreshKey +1

				if(window.localStorage.getItem("month_attendance_count") !== null){
					let month_attendance_record = JSON.parse(
						window.localStorage.getItem("month_attendance_count")
					);
					this.options.xaxis.categories = month_attendance_record[0]['categories'];
					this.series = month_attendance_record[0]['series'];
				}else{
					this.getAttendanceCount();
				}
			}
			if(this.attendance_day === 'today'){

				// if(window.localStorage.getItem("today_attendance_count") !== null){
				//   let today_attendance_record = JSON.parse(
				//     window.localStorage.getItem("today_attendance_count")
				//   );
				//   this.options.xaxis.categories = today_attendance_record[0]['categories'];
				//   this.series = today_attendance_record[0]['series'];
				// }else{
				this.getAttendanceCount();

				// }
			}
		},
		getAttendanceCount(){
			this.$rest.get('/api/get-attendance-count?attendance_for='+this.attendance_day)
				.then((res)=>{
					let $this = this;
					$this.options.xaxis.categories = [];
					$this.series = [];
					if(res.status = 200){
          
						let data = res.data.attendanceCount;
						let present_data = [];
						let absent_data = [];
						let leave_data = [];
						let late_data = [];
						data.map(function(item){
							$this.options.xaxis.categories.push(item.grade_name)
							present_data.push(item.total_present);
							absent_data.push(item.total_absent);
							leave_data.push(item.total_leave);
							late_data.push(item.total_late);
						});
						$this.series.push(
							{
								type: "column",
								name: "present",
								data: present_data
							},
							{
								type: "column",
								name: "absent",
								data: absent_data
							},
							{
								type: "column",
								name: "leave",
								data: leave_data,
							},
							{
								type: "column",
								name: "late",
								data: late_data,
							},
						)
						let attendance_data = [
							{
								categories:$this.options.xaxis.categories,
								series:$this.series
							}
						];
						let key_name = $this.attendance_day;
						window.localStorage.setItem(
							key_name+"_attendance_count",
							JSON.stringify(attendance_data)
						);
						if(key_name === 'today'){
							this.refreshKey = this.refreshKey +1
						}

						// }
					}
				})
		},
		setData(){
			localStorage.removeItem("month_attendance_count");
			localStorage.removeItem("year_attendance_count");
			localStorage.removeItem("today_attendance_count");  
			this.getAttendanceCount();

		}
	},
	watch: {
   
	},
	beforeDestroy() {
	},
};
</script>

<style lang="scss">
@import "@/assets/scss/_dashboard";
// delete the following line if no text is used
// edit the line if you wanna use other fonts
// @import url(https://fonts.googleapis.com/css?family=Open+Sans:700, 300);

// use only the available space inside the 400x400 frame

.frame {
  position: fixed;
  bottom: 20px;
  right: 2%;
  width: 100px;
  height: 100px;
  margin-top: -200px;
  margin-left: -200px;
  border-radius: 2px;
  //   box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.1);
  //   overflow: hidden;
  //   background: #031d44;
  //   color: #333;
  font-family: "Open Sans", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

svg#bulb {
  fill: #f6e27f;
  transition: all 0.4s ease-in-out;
}
svg#base {
  fill: #b1c1c0;
  margin-top: 5px;
}

.light {
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0px 0px 40px 15px #f6e27fa3;
  cursor: pointer;
  height: 40px;
  position: absolute;
  top: 0;
  width: 40px;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: none;
    transition: box-shadow 0.2s ease-in-out;

    + svg#bulb {
      fill: #b1c1c0;
    }
  }
}

.calendar-dash {
  .head_title {
    background-color: transparent !important;
  }
  .table {
    background-color: transparent !important;
  }
  .data-represent {
    margin-left: 0;
  }
}

.apexcharts-canvas {
  width: 100% !important;
}

.logged-in-info {
  position: relative;
  /*background: red;*/
  max-width: 500px;
  display: flex;

  .profile {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #9ba3ad;

    img {
      width: 100%;
    }
  }

  .pro-info {
    width: 70%;

    h4 {
      font-size: 12px;
      color: #c0c0c0;
      line-height: 1;
      margin-top: 0;
      display: block;
      width: 100%;

      i {
        font-size: 12px;
      }

      span.logged_in {
        float: right;
        font-weight: normal;

        strong {
          color: #888;
        }

        /*text-align: right;*/
      }
    }

    h2 {
      color: #172c44;
      font-size: 16px;
      opacity: 0.8;
      line-height: 0.8;

      + p {
        color: #999;
        font-size: 12px;
      }
    }
  }
}

/*<div class="representation">*/
/*</div>*/
/*<div class="member-label">*/
/*<h3>Total Member</h3>*/
/*<p>Male 201, Female 301</p>*/
/*</div>*/
/*<div class="counter">*/
/*<h2>304</h2>*/
/*</div>*/

.board-static-rep {
  position: absolute;
  right: -20px;
  opacity: 0.9;
  width: 300px;
  top: 0;
}

.school_info {
  position: relative;

  h1 {
    color: #172c44;
    font-weight: 500;
    font-size: 24px;
  }

  .icon-info {
    a {
      text-decoration: none;
      font-size: 13px;
      color: #758392;
      display: inline-block;
      margin-right: 20px;

      i {
        font-size: 16px;
        color: #758392;
      }

      &.disabled {
        opacity: 0.3;
      }

      &.facebook {
        color: #4267b2;

        i {
          color: #4267b2;
        }
      }

      &.instagram {
        color: #d82b7d;

        i {
          color: #d82b7d;
        }
      }

      &.twitter {
        color: rgb(29, 161, 242);

        i {
          color: rgb(29, 161, 242);
        }
      }
    }
  }
}

.dash-container {
  /*padding: 0 !important;*/
  .info-tab-section {
    padding-bottom: 20px;

    .theme--light {
      background-color: transparent !important;
    }
  }
}

.v-tabs__item {
  color: #888;
}

.v-tabs__item--active {
  color: #1976d2 !important;
  font-weight: bold;
}

.chart-container {
  padding: 20px 0 !important;
  margin-left: -60px;
  min-height: 330px;
}

.app-features {
  display: flex;
  min-height: 120px;
  background-color: white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);

  .feature-icon {
    flex: 1.3;
    align-items: center;
    justify-content: center;
    display: flex;
    /*background-color: red;*/
  }

  .feature-content {
    flex: 3;
    background-color: white;
    /*padding: 15px 15px;*/
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      p {
        color: #777;
        line-height: 15px;
        font-size: 13px;
      }
    }

    h3 {
      font-size: 18px;
    }

    h2 {
      color: #555;
      line-height: 14px;
      font-size: 20px;
      font-weight: 300;
    }
  }

  &.f-books {
    .feature-icon {
      background: -webkit-linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
      background: linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
    }

    h3 {
      color: #ff5252;
    }
  }

  &.f-sms {
    .feature-icon {
      background: -webkit-linear-gradient(45deg, #43a047 0%, #1de9b6 100%);
      background: linear-gradient(45deg, #43a047 0%, #1de9b6 100%);
    }

    h3 {
      color: #43a047;
    }
  }

  &.f-converter {
    .feature-icon {
      background: -webkit-linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
      background: linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
    }

    h3 {
      color: #0288d1;
    }
  }

  &.f-collection {
    .feature-icon {
      background: -webkit-linear-gradient(45deg, #b199dd 0%, #a389d3 100%);
      background: linear-gradient(45deg, #b199dd 0%, #a389d3 100%);
    }

    h3 {
      color: #a389d3;
    }
  }
}

.background-amount {
  background: white;
  background: -webkit-linear-gradient(45deg, white 0%, #dddddd 100%);
  background: linear-gradient(45deg, white 0%, #dddddd 100%);
}

.background-converter {
  background: #ff5252;
  background: -webkit-linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
  background: linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
}

.background-books {
  background: #0288d1;
  background: -webkit-linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
  background: linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
}

.background-sms {
  background: #43a047;
  background: -webkit-linear-gradient(45deg, #43a047 0%, #1de9b6 100%);
  background: linear-gradient(45deg, #43a047 0%, #1de9b6 100%);
}

.dash-container {
  min-height: 100px;
  width: 90%;
  /*padding: 40px 0 0 0;*/
  margin: 0 auto;

  div.dash-company-info {
    padding-top: 20px;
    display: flex;
    padding-bottom: 10px;
  }

  h1 {
    color: #555;
    font-weight: 300;

    + p {
      color: #888;
      font-size: 14px;
    }
  }

  .navigate-to {
    flex: 1.5;
    padding-top: 15px;

    ul li {
      list-style: none;
      display: inline;

      a {
        display: inline-block;
        border: 0.15em solid #c9c9c9;
        margin-right: 10px;
        height: 25px;
        font-size: 10px;
        width: 25px;
        text-align: center;
        line-height: 25px;
        border-radius: 50px;
        text-decoration: none;

        &:hover {
          i {
            color: #333 !important;
          }
        }
      }
    }
  }

  .intro {
    flex: 3;
  }
}

.profile-user-info {
  display: flex;

  .profile-image {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 200px;
    overflow: hidden;
    flex: 1;
    border: 1px solid white;

    img {
      margin: 0 auto 0 -10px;
      padding: 0;
      width: 118%;
    }

    width: 100px;
    height: 106px;
    /*background-color: red;*/
  }

  .profile-info {
    ul {
      margin: 0;
      padding: 0;
    }

    flex: 2;
    color: #555;
    padding-left: 15px;

    h3 {
      font-weight: normal;
      color: #333;
    }

    p {
      line-height: 15px;
    }

    .name-info {
      font-size: 14px;
      margin-bottom: 5px;
      color: #888;
      font-weight: bold;
      line-height: 10px;
      margin-bottom: 10px;
    }

    .email-info {
      color: #999999;
      font-size: 12px;
      padding-top: 0;
      margin-bottom: 10px;
    }

    .profile-nav {
      padding-top: 10px;
      border-top: 1px solid #e3e3e3;
    }

    /*background-color: green;*/
  }
}
</style>
<style lang="scss">
@import "@/assets/scss/_dashboard";
// delete the following line if no text is used
// edit the line if you wanna use other fonts
// @import url(https://fonts.googleapis.com/css?family=Open+Sans:700, 300);

// use only the available space inside the 400x400 frame

.frame {
  position: fixed;
  bottom: 20px;
  right: 2%;
  width: 100px;
  height: 100px;
  margin-top: -200px;
  margin-left: -200px;
  border-radius: 2px;
  //   box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.1);
  //   overflow: hidden;
  //   background: #031d44;
  //   color: #333;
  font-family: "Open Sans", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

svg#bulb {
  fill: #f6e27f;
  transition: all 0.4s ease-in-out;
}
svg#base {
  fill: #b1c1c0;
  margin-top: 5px;
}

.light {
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0px 0px 40px 15px #f6e27fa3;
  cursor: pointer;
  height: 40px;
  position: absolute;
  top: 0;
  width: 40px;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: none;
    transition: box-shadow 0.2s ease-in-out;

    + svg#bulb {
      fill: #b1c1c0;
    }
  }
}

.calendar-dash {
  .head_title {
    background-color: transparent !important;
  }
  .table {
    background-color: transparent !important;
  }
  .data-represent {
    margin-left: 0;
  }
}

.apexcharts-canvas {
  width: 100% !important;
}

.logged-in-info {
  position: relative;
  /*background: red;*/
  max-width: 500px;
  display: flex;

  .profile {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #9ba3ad;

    img {
      width: 100%;
    }
  }

  .pro-info {
    width: 70%;

    h4 {
      font-size: 12px;
      color: #c0c0c0;
      line-height: 1;
      margin-top: 0;
      display: block;
      width: 100%;

      i {
        font-size: 12px;
      }

      span.logged_in {
        float: right;
        font-weight: normal;

        strong {
          color: #888;
        }

        /*text-align: right;*/
      }
    }

    h2 {
      color: #172c44;
      font-size: 16px;
      opacity: 0.8;
      line-height: 0.8;

      + p {
        color: #999;
        font-size: 12px;
      }
    }
  }
}
/*<div class="representation">*/
/*</div>*/
/*<div class="member-label">*/
/*<h3>Total Member</h3>*/
/*<p>Male 201, Female 301</p>*/
/*</div>*/
/*<div class="counter">*/
/*<h2>304</h2>*/
/*</div>*/

.board-static-rep {
  position: absolute;
  right: -20px;
  opacity: 0.9;
  width: 300px;
  top: 0;
}

.school_info {
  position: relative;

  h1 {
    color: #172c44;
    font-weight: 500;
    font-size: 24px;
  }

  .icon-info {
    a {
      text-decoration: none;
      font-size: 13px;
      color: #758392;
      display: inline-block;
      margin-right: 20px;

      i {
        font-size: 16px;
        color: #758392;
      }

      &.disabled {
        opacity: 0.3;
      }

      &.facebook {
        color: #4267b2;

        i {
          color: #4267b2;
        }
      }

      &.instagram {
        color: #d82b7d;

        i {
          color: #d82b7d;
        }
      }

      &.twitter {
        color: rgb(29, 161, 242);

        i {
          color: rgb(29, 161, 242);
        }
      }
    }
  }
}

.dash-container {
  /*padding: 0 !important;*/
  .info-tab-section {
    padding-bottom: 20px;

    .theme--light {
      background-color: transparent !important;
    }
  }
}

.v-tabs__item {
  color: #888;
}

.v-tabs__item--active {
  color: #1976d2 !important;
  font-weight: bold;
}

.chart-container {
  padding: 20px 0 !important;
  margin-left: -60px;
  min-height: 330px;
}

.app-features {
  display: flex;
  min-height: 120px;
  background-color: white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);

  .feature-icon {
    flex: 1.3;
    align-items: center;
    justify-content: center;
    display: flex;
    /*background-color: red;*/
  }

  .feature-content {
    flex: 3;
    background-color: white;
    /*padding: 15px 15px;*/
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      p {
        color: #777;
        line-height: 15px;
        font-size: 13px;
      }
    }

    h3 {
      font-size: 18px;
    }

    h2 {
      color: #555;
      line-height: 14px;
      font-size: 20px;
      font-weight: 300;
    }
  }

  &.f-books {
    .feature-icon {
      background: -webkit-linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
      background: linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
    }

    h3 {
      color: #ff5252;
    }
  }

  &.f-sms {
    .feature-icon {
      background: -webkit-linear-gradient(45deg, #43a047 0%, #1de9b6 100%);
      background: linear-gradient(45deg, #43a047 0%, #1de9b6 100%);
    }

    h3 {
      color: #43a047;
    }
  }

  &.f-converter {
    .feature-icon {
      background: -webkit-linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
      background: linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
    }

    h3 {
      color: #0288d1;
    }
  }

  &.f-collection {
    .feature-icon {
      background: -webkit-linear-gradient(45deg, #b199dd 0%, #a389d3 100%);
      background: linear-gradient(45deg, #b199dd 0%, #a389d3 100%);
    }

    h3 {
      color: #a389d3;
    }
  }
}

.background-amount {
  background: white;
  background: -webkit-linear-gradient(45deg, white 0%, #dddddd 100%);
  background: linear-gradient(45deg, white 0%, #dddddd 100%);
}

.background-converter {
  background: #ff5252;
  background: -webkit-linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
  background: linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
}

.background-books {
  background: #0288d1;
  background: -webkit-linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
  background: linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
}

.background-sms {
  background: #43a047;
  background: -webkit-linear-gradient(45deg, #43a047 0%, #1de9b6 100%);
  background: linear-gradient(45deg, #43a047 0%, #1de9b6 100%);
}

.dash-container {
  min-height: 100px;
  width: 90%;
  /*padding: 40px 0 0 0;*/
  margin: 0 auto;

  div.dash-company-info {
    padding-top: 20px;
    display: flex;
    padding-bottom: 10px;
  }

  h1 {
    color: #555;
    font-weight: 300;

    + p {
      color: #888;
      font-size: 14px;
    }
  }

  .navigate-to {
    flex: 1.5;
    padding-top: 15px;

    ul li {
      list-style: none;
      display: inline;

      a {
        display: inline-block;
        border: 0.15em solid #c9c9c9;
        margin-right: 10px;
        height: 25px;
        font-size: 10px;
        width: 25px;
        text-align: center;
        line-height: 25px;
        border-radius: 50px;
        text-decoration: none;

        &:hover {
          i {
            color: #333 !important;
          }
        }
      }
    }
  }

  .intro {
    flex: 3;
  }
}

.profile-user-info {
  display: flex;

  .profile-image {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 200px;
    overflow: hidden;
    flex: 1;
    border: 1px solid white;

    img {
      margin: 0 auto 0 -10px;
      padding: 0;
      width: 118%;
    }

    width: 100px;
    height: 106px;
    /*background-color: red;*/
  }

  .profile-info {
    ul {
      margin: 0;
      padding: 0;
    }

    flex: 2;
    color: #555;
    padding-left: 15px;

    h3 {
      font-weight: normal;
      color: #333;
    }

    p {
      line-height: 15px;
    }

    .name-info {
      font-size: 14px;
      margin-bottom: 5px;
      color: #888;
      font-weight: bold;
      line-height: 10px;
      margin-bottom: 10px;
    }

    .email-info {
      color: #999999;
      font-size: 12px;
      padding-top: 0;
      margin-bottom: 10px;
    }

    .profile-nav {
      padding-top: 10px;
      border-top: 1px solid #e3e3e3;
    }

    /*background-color: green;*/
  }
  .overlay .inner-overlay p{
    margin-top: 40%;
  }
}
</style>
